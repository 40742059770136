import { render, staticRenderFns } from "./Sph.vue?vue&type=template&id=60606760&scoped=true"
import script from "./Sph.vue?vue&type=script&lang=js"
export * from "./Sph.vue?vue&type=script&lang=js"
import style0 from "./Sph.vue?vue&type=style&index=0&id=60606760&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60606760",
  null
  
)

export default component.exports