<template>
<div class="sph">
  <van-nav-bar
    title="视频号"
    fixed
    left-arrow
    @click-left="$router.go(-1)"
  />
  <div class="info">
    <van-field
      v-model="sphInfo.name"
      label="名称"
      placeholder="视频号名称"
      input-align="right"
    />
    <van-field
      v-model="sphInfo.sph_id"
      label="视频号ID"
      placeholder="视频号ID"
      input-align="right"
    />
    <van-field
      v-model="sphInfo.intro"
      rows="2"
      input-align="right"
      autosize
      label="介绍"
      type="textarea"
      maxlength="50"
      placeholder="视频号简介"
      show-word-limit
    />
    <van-button class="save-btn" type="primary" block @click="onSave">保存</van-button>

  </div>

</div>
  
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Sph",
  data() {
    return {
      loading: false,
      sphInfo: {
        name: '',
        id: '',
        intro: ''
      }
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
        this.loading = true;
        this.$http.call(this.$http.api.getSph).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              if(resp.data){
                this.sphInfo = resp.data;
              }
            }else{
                Toast(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    onSave() {
      let data = this.sphInfo;
      console.log(data);

      this.$http.call(this.$http.api.postSph, {
        data
      }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
              Toast('已保存');
            }else{
                Toast(resp.msg);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
  },
};
</script>
<style lang="less" scoped>
.sph {
    padding: 60px 0;
    .info {
      padding: 15px;
      .save-btn {
        margin-top: 15px;
      }
    }
}
</style>
